<template>
  <div class="patient__History">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-9
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.dailyProcessReport") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              size="mini"
              v-model="filterForm.from"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              size="mini"
              v-model="filterForm.to"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="
            col-3
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <export-excel
            v-can="'organizations.excel'"
            class="btn excel_btn"
            :data="list.data"
            :fields="excel_fields"
            @fetch="controlExcelData()"
            worksheet="Отчет по процессам"
            name="Отчет_по_процессам.xls"
          >
            <el-button size="mini">
              <i class="el-icon-document-delete"></i> Excel
            </el-button>
          </export-excel>

          <el-button
            v-can="'specialties.create'"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
          >
            {{ $t("message.create") }}
          </el-button>
        </div>
      </div>

      
        <el-row :gutter="20">
          <el-col :span="8"> <el-card class="box-card">
            <div class="itme-p-r"> <div>Общая сумма выплат:</div> <b>{{ list.amount }}</b> </div> 
         </el-card> </el-col>
           <el-col :span="8"> <el-card class="box-card">
            <div class="itme-p-r"> <div>Процессов:</div> <b>{{ list.process_count }}</b> </div> 
         </el-card> </el-col>
           <el-col :span="8"> <el-card class="box-card">
            <div class="itme-p-r"> <div>Заказов: </div> <b>{{ list.order_count.length }}</b> </div> 
         </el-card> </el-col>
          
        </el-row>
      

  
      <table
        class="table table-bordered table-hover mt-4  "
        v-loading="loadingData"
      >
        <thead>
          <th>{{ $t("message.doctor") }}</th>
          <th v-for="item in list.column" :key="item.id">
            {{ item.name }}
          </th>
        </thead>

        <tbody>
          <tr v-for="(item, i) in list.data" :key="i">
            <td>{{ item.doctor_name }}</td>
            <td v-for="process in list.column" :key="process.id">
              {{ count(item, process) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";

export default {
  mixins: [list],
  data() {
    return {
      loadingData: false,
      excel_fields: {},
      selectedModel: {},
    };
  },
  async mounted() {
    await this.updateList();
    await this.controlExcelData();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      list: "doctorProcessReport/list",
      columns: "doctorProcessReport/columns",
      pagination: "doctorProcessReport/pagination",
      filter: "doctorProcessReport/filter",
      sort: "doctorProcessReport/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "doctorProcessReport/index",
      updateSort: "doctorProcessReport/updateSort",
      updateFilter: "doctorProcessReport/updateFilter",
      updateColumn: "doctorProcessReport/updateColumn",
      updatePagination: "doctorProcessReport/updatePagination",
      showModel: "doctorProcessReport/show",
      empty: "doctorProcessReport/empty",
      delete: "doctorProcessReport/destroy",
      refreshData: "doctorProcessReport/refreshData",
    }),
    count(val1, val2) {
      return val1[val2.name];
    },
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    controlExcelData() {
      this.excel_fields = {
        Врач: "doctor_name",
      };
      if (this.list.column)
        this.list.column.forEach((element) => {
          this.excel_fields[element.name] = element.name;
        });
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    edit(model) {
      this.selectedModel = model;
      this.drawer.update.status = true;
    },
    show(model) {
      this.showModel(model.id)
        .then((res) => {
          this.reopenUpdate = true;
          this.drawerShow = true;
          this.drawer.show.status = true;
        })
        .catch((err) => {});
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
<style lang="scss">
.itme-p-r{
  display: flex;
  b{
    margin-left: 10px;
  }
}
</style>